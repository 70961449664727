import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Template from 'src/slides/desktop/_templates/chapter_5/sub_1/slide1';

const Slide = () => {
  const query = useStaticQuery(graphql`
  query {
    allMdx(filter: {frontmatter: {language: {eq: "FR"}, title: {eq: "slide-5-1-1"}}}) {
      edges {
        node {
          frontmatter {
            author
            authorInfo
            title
          }
          body
        }
      }
    },
  }
  `);
  return (
    <Template query={query} />
  );
};


export default Slide;
